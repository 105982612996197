import React, { useState } from 'react'

import { useForm } from 'react-hook-form'
import { GoogleIcon } from '../svg/google.jsx'
import * as styles from '@css/signup.module.css'
import { EnterIcon } from '../svg/enter.jsx'

export const SignUp = () => {

  const { register, handleSubmit, formState: { errors } } = useForm()
  
  const signUpWithGoogle = () => {}

  const submitForm = e => {
    console.log(e)
  }


  return (
    <section className={styles.section}>
      <div className={styles.title}>
        <h3>Sign up</h3>
      </div>
      <button
        type="button"
        className={styles.providerBtn}
        onClick={signUpWithGoogle}
        >
        <GoogleIcon classes="w-12"/>
        <span>Sign up with Google</span>
      </button>
      <div className={styles.lineTitle}>
        <span>OR</span>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(submitForm)}>
        <div className={styles.inputCtrl}>
          <input
            type="email"
            placeholder="Email address"
            error={errors.email?.message}
            {...register('email', {
              required: 'Email is required',
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Email format is wrong'
            })}
          />
           {errors.email && <span className="text-sm text-red-500" role="alert">{errors.email?.message}</span>} 
        </div>
        
        <div className={styles.inputCtrl}>
          <input
            type="password"
            placeholder="Password"
            error={errors.password?.message}
            {...register('password', {
              required: 'Password is required',
              minLength: {
                value: 5,
                message: 'Password is too short!',
              },
            })}
          />
          {errors.password && <span className="text-sm text-red-500" role="alert">{errors.password?.message}</span>}  
        </div>
        
        <div className={styles.inputCtrl}>
          <input 
            type="text" 
            placeholder="Coupon" 
            {...register('coupon')}
          />
        </div>
       
        <button type="submit" className={styles.signBtn}>
          Sign up
        </button>
      </form>
          
      <div className={`mt-5 ${styles.lineTitle}`}>
        <span>Already a member?</span>
      </div>
      <button
        type="button"
        className={`mt-3 ${styles.providerBtn}`}
        // onClick={signUpWithGoogle}
        >
        <EnterIcon classes="fill-indigo-400 w-7 ml-2"/>
        <span className='mr-0 py-2'>Sign in</span>
      </button>
    </section>
  )
}
