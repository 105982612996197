import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { subscribe } from 'pubsub-js'
import '../css/layout.css'
import '../css/shared.css'
import { Modal } from 'flowbite-react'
import { SignUp } from './Signup'
import { CloseIcon } from '../svg/close'


export const Layout = ({ children }) => {
  const [opened, setOpened] = useState(false)
  const [joined, setJoined] = useState(false)
  const [modalState, setModalState] = useState(false)

  subscribe('joined-the-waitlist', () => {
    setJoined(true)
  })

  const onClick = () => {
    setModalState(true)
  }

  const onClose = () => {
    setModalState(false)
  }

  return (
    <>
      <div className="site-container">
        <main>{children}</main>
        {/* <button
          className={classNames({
            'glowing-button': true,
            'floating-join-button': true,
            success: joined,
          })}
          onClick={onClick}
        >
          Get amazing avatars today!
        </button> */}
        <a
          className={classNames({
            'glowing-button': true,
            'floating-join-button': true,
            success: joined,
          })}
          href={process.env.REACT_APP_SIGNUP_LINK}
        >
          Get 100+ amazing avatars today!
        </a>
      </div>

      <Modal show={modalState} size="md" popup={true}>
          <div className='relative h-6'>
            <button type='button' className="absolute w-4 h-4 rounded-full right-3 top-2 " onClick={onClose}>
              <CloseIcon classes="fill-gray-400"/>
            </button>
          </div>
        <Modal.Body>
          <SignUp />
        </Modal.Body>
      </Modal>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
